@use 'styles/variables' as var;
@use 'styles/mixins' as mix;

#cx-payoutable-form {
    .payoutable-data-container {
        padding: var.$regularPad;
    }

    .card-title {
        padding-bottom: 8px;
        justify-content: flex-start;

        > p {
            color: var.$mediumblack;
            font: 400 #{var.$small}/14.06px var.$primaryfont;
            letter-spacing: 0.09px;
            text-align: left;
        }

        > .material-icons-outlined {
            font-size: var.$large;
            line-height: 14px;
            margin-left: -6px;
        }
    }

    .card-body {
        @include mix.flex-gap(var.$smallPad, $direction: column);
    }

    .cx-search-input {
        input {
            max-width: 220px;
        }
    }
}
