@use '@material/textfield';
@use 'styles/variables' as var;
@use 'styles/mixins' as mix;
@forward '@chicksgroup/styles/colors';

#cx-payment-method-selector {    
    @include textfield.label-color(var(--cg-grey-700));

    .payment-method-text-field {

        .mdc-text-field {
            &__input {
                padding-right: 44px;
                padding-left: 8px;
            }
            
            &--outlined {
                height: 43px;
            }
    
            &__icon--leading {
                margin: 0;
            }
    
            > .mdc-floating-label {
                display: block;
                font-size: var.$regular;
                line-height: var.$medium;
                left: 28px;
                max-width: calc(100% - 30px);
                padding-left: 4px;
            }

            &.selected {
                .mdc-floating-label {
                    transform: translateY(-106%) !important;
                    font-size: 12px;
                    color: var(--cg-grey-700);
                }
            }

            input {
                font-size: var.$regular;
                line-height: 16px;
                color: var.$mediumblack;
            }

            &:has(.payment-method-image__bank) mdc-icon {
                display: flex;
                height: 34px;
                align-items: center;
            }
        }
    }

    .expanded-selected {
        margin-top: 3px !important;
        z-index: 50;
        width: 100%;
        touch-action: pan-y pan-x;
        padding: 0;

        &.outlined-selector {
            margin-top: 14px !important;

            @media (width <= var.$desktop) {
                margin-top: 3px !important;
            }
        }
    }

    .balance-container {
        display: none;
        position: absolute;
        top: 40px;
        left: 75px;
    }

    .balance-container:hover {
        display: block;
    }

    .expand-icon {
        --cds-icon-color: var(--cg-grey-700);
        --md-icon-size: 16px;
        position: absolute;
        right: 10px;
        transform: rotate(0deg);
        transition: 0.3s ease;
        color: var.$purple-text;
    }

    .show {
        .expand-icon {
            transform: rotate(180deg);
            transition: 0.3s ease;
        }
    }

    .balance-text {
        cursor: pointer;
        margin-bottom: 5px;
    }

    .btn:focus {
        box-shadow: none;
    }

    .mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path {
        animation: none;
    }

    .expired-date {
        display: block;
    }

    .action-buttons-container {
        display: none;

        & .action-button:first-child {
            margin-right: 6px
        }
    }

    .back-button {
        float: right;
        margin-bottom: 18px;
    }

    .action-button {
        @include mix.circle(18px);
        background-color: transparent;
    }

    .close-icon {
        top: 30px;
        right: 28px;
    }

    .payment-method-image {
        max-width: 24px;
        max-height: 100%;
    }

    .payment-method-img {
        display: inline-block;
        vertical-align: middle;
        width: 24px;

        img {
            height: auto;
            max-height: 100%;
            max-width: 24px;
            vertical-align: middle;
            width: auto;
        }
    }

    .payment-method-selector {
        height: 50px;
        max-height: 50px;
        color: var.$input-placeholder;
        border-radius: 4px;
        border: none;
        padding: var.$smallPad var.$smallPad var.$smallPad 15px;

        &:hover {
            outline: 1px solid var.$purplehover;
            color: var.$input-placeholder-hover;
        }
        
        &:focus {
            outline: 1px solid var.$purple-text;
            color: var.$black;
        }

        mdc-icon {
            color: var.$purple-text;
            height: 24px;
            display: flex;
        }
    }
    
    .selected-method-balance {
        position: absolute;
        top: 6px;
    }

    .payment-icon {
        display: inline-block;

        @media (width <= var.$phone) {
            width: 48px;
        }
    }

    .payment-method-balance {
        cursor: pointer;
        font-size: 9px;
        top: 28px;
        left: 74px;
    }

    .custom-checkbox {
        padding: 2px !important;
        width: 16px !important;
        height: 16px !important;
        top: 0;
        left: 0;

        .mdc-checkbox__native-control,
        .mdc-checkbox,
        .mdc-checkbox__background {
            padding: 2px !important;
            width: 16px !important;
            height: 16px !important;
            top: 0;
            left: 0;
        }
    }

    .payment-method-scrollbar {
        max-height: 387px;
        padding: var.$regularPad var.$smallPad;

        &.form {
            padding: 0;
        }
    }

    .payment-method-scrollbar .simplebar-horizontal {
        display: none !important;
    }

    .simplebar-scrollbar::before {
        background-color: var.$purple-text !important;
    }

    @media (width <= var.$desktop) {
        .payment-method-component {
            max-width: unset;
        }
    }

    .p-btn {
        border-radius: 4px;
        background-color: var.$dropdownmenu;
        border: none;
    }

    .credit-card-container {
        padding: 10px 15px 0;
    }

    .spin-loader {
        height: 40px
    }
    
    &.cx-form-select:not(.mdc-text-field--disabled){
        background-color: transparent;
    }

    #cx-no-results {
        padding: 0 16px;

        img {
            @include mix.square(32px);
        }

        span {
            font: 300 14px/16px var.$primaryfont;
            letter-spacing: 0px;
        }
    }

    .pm-border {
        padding-top: 10px !important;
        border-top: solid 1px var.$secondaryborder;
    }
}
