@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@use '@material/textfield';
@use "@material/button/styles";
@use "@material/button";

#cx-order-section {
    $color-text-default: rgba(68, 68, 68, 1);

    .single-container {
        background: v.$white;
        padding: v.$regularPad;
        max-width: 928px;
        min-height: 642px;

        @include m.flex-gap(v.$regularPad, $direction: column);
    }

    .orders-container {
        padding-inline: v.$regularPad;
        padding-top: v.$regularPad;
        padding-bottom: v.$bigPad;
        box-shadow: none;
        @include m.flex-gap(v.$bigPad, $direction: column);

        @media (width > v.$desktop) {
            min-height: 642px;

            &__empty {
                min-height: unset;
                gap: 20px !important;
            }
        }

        @media (width <= v.$desktop) {
            padding: 0;
            border-radius: 0;
            background: transparent;
        }

        @include m.media(v.$phone) {
            padding: 0;
            gap: v.$regularPad;
        }
    }

    .section-title {
        font-size: v.$extralarge;
        color: $color-text-default;
    }

    #cx-order-row {
        @include m.media-range(v.$phone, v.$desktop) {
            .order-row {
                padding-left: v.$regularPad;
            }
        }
    }

    .return {
        transform: translateX(-10px);
        margin-bottom: 15px;
    }

    .sort-container {
        font-size: 12px;
        font-weight: 300;
        line-height: 14.06px;
        letter-spacing: 0.45px;
        color: v.$midgray;
    }

    .pagination {
        margin-bottom: 0;

        @include m.media(v.$phone) {
            margin-top: v.$regularPad;
        }
    }

    .mobile-top-row {
        justify-content: space-between !important;

        .mobile-top-data {
            flex: 0 1 62%;
            justify-content: space-between !important;

            @media (width <= v.$smallphone){
                flex-basis: 65%;
                gap: 10px !important;
            }

        }
    }

    .mobile-bottom-row {
        margin-left: 0 !important;
        justify-content: space-between !important;
    }

    .crying-chick {
        margin-bottom: 17px;
        margin-top: 27;
    }

    .empty-caption {
        width: 50%;
    }

    .searchbar-container {
        padding-block: 7px v.$smallPadPlus;

        @include m.media-range(v.$phone, v.$desktop) {
            padding-block: v.$small 43px;
        }

        @include m.media(v.$phone) {
            padding-block: 7px v.$regularPad;
        }

        .create-order-button {
            width: 210px;
            height: 50px;
            margin-left: v.$smallPadPlus;
            border-radius: v.$thinborder;

            @media (width <= v.$phone) {
                width: 100%;
                margin-left: 0;
            }
        }

        a {
            width: unset;

            @media (width <= v.$phone) {
                width: 100%;
            }
        }
    }

    .input-with-label {
        @include textfield.label-color(v.$purplehover);
        @include textfield.fill-color(v.$white);
        box-shadow: 0px 1.608px 64.335px 0px rgba(0, 0, 0, 0.06);

        .mdc-text-field__icon--leading {
            color: v.$purplehover !important;
        }

        &:hover {
            outline: 1px v.$purplehover solid;
        }

        &.mdc-text-field--focused  {
            outline: 2px v.$cx-purple solid;
        }
    }

    .skeleton-configuration {
        animation: skeletonLoading .5s linear infinite alternate;
        border-radius: v.$thinborder;
    }

    .back-skeleton {
        @include m.skeleton-background();
        @include m.size(60px, 24px);

        @media (width <= v.$tablet) {
            display: none;
        }
    }

    .skeleton-container {
        @include m.skeleton-background();
        margin-bottom: v.$regularPad;

        &:nth-child(odd) {
            opacity: .40;
        }

        &:nth-child(1) {
            @include m.size(200px, 28px);
            margin-bottom: 16px;
        }

        @include m.media(v.$desktop) {
            &:nth-child(1) {
                @include m.size(150px, 24px);
            }
        }

        @include m.media(v.$phone) {
            height: v.$extrabigpad;
            margin-bottom: v.$regularPad;

            &:nth-child(1) {
                margin-bottom: 25px;
            }

            &:last-child {
                @include m.size(230px, v.$mediumPad);
                margin-top: v.$regularPad;
            }
        }
    }

    .filter-skeleton-elements-first {
        @extend .searchbar-container;
        gap: v.$regularPad;

        .inner-skeleton-element {
            @include m.skeleton-background();

            &:nth-child(1) {
                @include m.size(692px, v.$extrabigpad);
            }

            @include m.media(v.$desktop) {
                &:nth-child(1) {
                    @include m.size(498px, v.$extrabigpad);
                }
            }

            @include m.media(v.$phone) {
                &:nth-child(1) {
                    @include m.size(75px, v.$extrabigpad);
                }

                &:nth-child(2) {
                    @include m.size(240px, v.$extrabigpad);
                }
            }
        }
    }

    .filter-skeleton-elements-second {

        .inner-skeleton-element {
            @include m.skeleton-background();

            &:nth-child(1) {
                @include m.size(121px, 12px);
            }

            &:nth-child(2) {
                @include m.size(188px, v.$extrabigpad);
            }
        }
    }

    .skeleton-container-table {
        gap: v.$regularPad;

        .skeleton-container-rows {
            @include m.skeleton-background();
            @include m.size(882px, v.$largePad);

            &:nth-child(1) {
                margin-top: v.$bigPad;
                @include m.size(852px, 12px);
            }

            &:nth-child(8) {
                margin-top: v.$regularPad;
                @include m.size(330px, 41px);
            }

            @include m.media(v.$desktop) {
                @include m.size(728px, v.$largePad);

                &:nth-child(1) {
                    margin-bottom: 10px;
                    @include m.size(574px, 12px);
                }

                &:nth-child(8) {
                    margin-block: v.$regularPad v.$bigPad;
                    @include m.size(250px, v.$bigPad);
                }
            }
        }

        @include m.media(v.$desktop) {
            gap: 0.625rem;
        }
    }

    .skeleton-container-style {
        outline: none;
    }

    .container-portal-style.area {
        @extend .orders-container;

        @include m.media(v.$desktop, false) {
            min-height: 662px;
        }

        @include m.media(v.$desktop) {
            padding: 0;
            box-shadow: none;
            height: fit-content;
        }

        @include m.media(v.$phone) {
            gap: 1.25rem;
        }

        & section {
            @include m.flex(stretch, flex-start, column);
            flex-wrap: wrap;
            gap: 1.25rem;

            @include m.media(v.$phone) {
                margin-bottom: 1.25rem !important;
            }
        }
    }

    .mobile-orders-skeleton-conctainer {
        gap: v.$smallPadPlus;
        margin-top: v.$smallPad;
        border-radius: v.$thinborder;

        .order-skeleton-element {
            @extend .skeleton-configuration;
            height: 41px;

            &:nth-child(even) {
                opacity: .40;
            }

            @include m.media(v.$phone) {
                height: 34px;
            }
        }
    }

    .button-search-drawer {
        border-radius: 4px;
        border: none;
        appearance: none;
        letter-spacing: 0.15px;
        text-align: center;
        -webkit-appearance: none;
        padding-inline: 26px;
        margin-right: 20px;
        max-width: 75px;

        @include button.container-fill-color((
            'default': v.$white,
            'hover': v.$secondarycolor-hover,
            'pressed': v.$secondarycolor-hover,
            'disabled': v.$secondarycolor));
        @include button.filled-accessible(v.$white);
        @include button.ink-color(v.$purple-text);

        &:hover {
            @include button.filled-accessible(v.$secondarycolor-hover);
        }

        &:active {
            @include button.filled-accessible(v.$secondarycolor-hover);
            background: v.$secondarycolor-hover !important;
        }

        &:disabled {
            opacity: 0.2;
        }

        & .material-icons {
            font-size: v.$largeplusplus;
        }
    }

    @media (width <= v.$tablet) {
        .single-container {
            padding: 28px 20px;
        }
    }

    @media (width <= v.$phone) {
        .single-container {
            padding: 10px;
            min-height: 315px;
        }

        .crying-chick {
            width: 174px;
            height: 156px;
            margin-bottom: 15px;
            margin-top: 15px;
        }

        .empty-caption {
            width: 80%;
        }
    }
}
