@use 'styles/variables' as var;
@use 'styles/mixins' as mix;

#cx-password-input {
    .circle-icon {
        position: absolute;
        top: 16.5px;
        right: 16px;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 11px;
        top: 12px;
    }

    .heading {
        @include mix.flex(center);
        line-height: var.$medium;
        margin-bottom: var.$extrasmall;
        letter-spacing: .1px;

        .material-icons {
            @include mix.flex(initial, flex-end);
            width: 16px;
            line-height: 17px;
        }

        &.skeleton-loading {
            height: 16px !important;
            width: 113px !important;
        }
    }

    .text-backface-visibility,
    .progress {
        @include mix.no-spacing(true);
        margin-top: 8px !important;
        font-size: var.$regular;
        line-height: 16px;
        letter-spacing: 0.001em;
    }

    @include mix.media(var.$phone) {

        .mdc-text-field__input,
        .text-backface-visibility {
            font-size: var.$small;
        }
    }

    .forgot-text {
        font-size: var.$small;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.001em;
        margin-top: 10px !important;

        &.skeleton-loading {
            display: none;
        }
    }
}
