@use 'styles/variables' as var;
@use 'styles/mixins' as m;
@import "styles/animations";

#cx-notification {

    .notification-header,
    .notification-description {
        max-width: 600px;

        @media (width <= var.$desktop) {
            max-width: 361px;
        }

        @media (width <= var.$phone) {
            max-width: calc(100% - 45px);
        }
    }

    .icon-container {
        margin-right: var.$regularPad;

        @include m.media(var.$phone) {
            margin-right: 15px;
        }
    }

    .notification-body {
        padding-bottom: var.$tinyPad;
    }

    .notification-info {
        margin-top: 5px;
        padding-left: var.$regularPad;

        @include m.media(var.$phone) {
            margin-top: 0;
        }
    }

    .notification-header {
        font-size: var.$medium;
        line-height: 25px;

        @media (max-width: var.$desktop) {
            font-weight: 500;
        }
    }

    .notification-description {
        font-size: var.$regular;
        line-height: var.$regularPad;

        @media (max-width: var.$desktop) {
            line-height: 14.06px;
        }

        @media (max-width: var.$phone) {
            font-weight: 300;
        }
    }

    .notification-date {
        opacity: 0.5;
        color: var.$midgray;
        right: 16px;
        margin-bottom: 2px;

        @media (width <= var.$desktop) {
            bottom: 10px;
        }

        @include m.media(var.$tablet) {
            font-weight: 300;
            font-size: var.$small !important;
        }

        @media (width <= var.$phone) {
            min-width: 125px;
        }
    }

    .material-icons-outlined {
        min-width: 25px;
        min-height: 25px;
    }

    &:hover {
        background: var.$lilac-hover !important;
    }

    .checkbox-notification {
        height: 25px;
        width: 25px;
        border-radius: 3px;

        & .bullet {
            height: 25px;
            width: 25px;
            border-radius: 3px;
            background-color: var.$inputgray;
        }

        &:hover {
            & .bullet {
                outline: 1px solid var.$purplehover;
            }
        }
    }


    .bullet-active {
        background: var.$purple-text !important;

        &:hover {
            &.bullet {
                outline: none;
            }
        }
    }
}

.notification-divisor {
    width: 100%;
    margin: 0;
    bottom: 0;
    opacity: 1;
}

.divisor-container {
    padding-inline: var.$regularPad;
}

.notification-image {
    @include m.square(25px);
}

#skeleton-animation {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .animation-container {
        height: 130px - 66px;
    }

    .icon-placeholder {
        width: 28px !important;
        height: 28px !important;
        background: linear-gradient(-45deg, var.$select-gray, var.$offlilac, var.$select-gray);
        background-size: 400% 400%;
        animation: gradient 4s ease infinite;
        border-radius: 100%;
    }

    .skeleton-placeholder {
        height: 20px;
        border-radius: 4px;
        background: linear-gradient(-45deg, var.$select-gray, var.$offlilac, var.$select-gray);
        background-size: 400% 400%;
        animation: gradient 4s ease infinite;
    }

    .title {
        width: 65%;
    }

    .content {
        width: 45%;
    }

    .date {
        width: 20%;
    }
}
