@import 'styles/variables';

#cx-paragraph-title {
    > div:first-child {
        max-width: 482px;
    }

    > div:nth-child(2) {
        letter-spacing: 0.448px;
    }
    
    @media (max-width: $desktop) {
        
        .text-medium {
            font-size: $regular;
        }
    }

    @media (max-width: $phone) {
        .text-medium {
            font-size: $small;
        }
    }
}
