@use 'styles/variables';
@use 'styles/mixins';

#cx-referral-row {
    .inner {
        margin: 0 0 20px 0;
        background-color: variables.$white;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .truncated-span {
        @include mixins.truncate-text(1);
        display: inline-block;
        white-space: nowrap;
        padding-right: 20px;
    }

    .truncated-span-wrapper {
        @include mixins.flex($direction: column, $justify: center);

        @media (width <= variables.$smallphone) {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    @media (width <= variables.$tablet) {
        .inner {
            height: 90px;
            margin: 0 0 10px 0;
        }
    }

    .currency-icon {
        color: variables.$cx-purple;
    }
}

#cx-referral-row-mobile {
    .mobile-inner {
        margin-top: 10px;
    }

    .row-mobile {
        height: 120px;
        background-color: variables.$white;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 4px;
    }

    .small-spacer {
        margin-bottom: 2px;
    }

    .truncated-span {
        @include mixins.truncate-text(1);
        display: inline-block;
        white-space: nowrap;
        max-width: 240px;
    }

    .truncated-span-wrapper {
        @include mixins.flex($align: center);

        @media (width <= variables.$smallphone) {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    @media (width <= variables.$phone) {
        .row-mobile {
            height: auto;
        }
    }

    .currency-icon {
        color: variables.$cx-purple;
    }
}
