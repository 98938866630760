@use 'styles/variables' as var;
@use 'styles/mixins' as mix;

#cx-payment-method-item {
    height: 50px;

    .balance-text {
        cursor: pointer;
        margin-bottom: 5px;
    }

    .payment-method-item {        
        transform: translateX(var(--displacementItem, 0px));
        background-color: var(--cg-grey-100);

        &.payment-method-item--deleting {
            & .payment-method-item-content {
                .text-delete {
                    letter-spacing: 0.1px;
                    line-height: var.$regular;
                }

                >div {
                    background: var.$secondarycolor;
                    padding: 5px;
                    border-radius: 4px;
                }

                & .action-button--deleting {
                    &:hover {
                        background-color: var.$secondarycolor-hover;
                    }
                }
            }
        }

        &-border {
            border-bottom: 1px solid var.$secondaryborder;
        }

        &-content {
            padding: 5px 0;
            
            &:last-child {
                border-bottom: none;
            }

            >div {
                padding: 7px 5px;
            }
        }
    }    

    &:not(:has(.payment-method-item--deleting)) .payment-method-item-content:hover {
        @media (width >= var.$desktop) {
            >div {
                background: var.$secondarycolor;
                border-radius: 4px;
            }
        }

        .expired-date {
            @media (width >= var.$desktop) {
                display: none;
            }
        }

        .action-buttons-container {
            @media (width >= var.$desktop) {
                display: flex;
            }
        } 
    }
        
    .payment-method-item-content {
        &.payment-method-item-content--right {
            background: var.$purple-text;
        }

        &.payment-method-item-content--left {
            background: var.$red-color;
        }

        & .payment-method-item-icon {
            &--left {
                transform: translateX(var(--displacementIconDelete, 0px)); 
            }

            &--right {
                transform: translateX(var(--displacementIconEdit, 0px)); 
            }
        }
    }

    .expired-date {
        display: block;
    }

    .action-buttons-container {
        display: none;

        &.no-hide {
            display: flex;
        }

        & .action-button:first-child {
            margin-right: 6px
        }

        &.action-button-white {
            .material-icons {
                color: var.$purple-text !important;
                font-size: var.$tiny !important;
            }
        }
    }

    .delete-item-container {
        &.end-align {
            flex: 1;
            justify-content: flex-end;
        }
    }

    .action-button {
        @include mix.circle(18px);

        &:hover {
            background-color: var.$secondarycolor-hover;
        }

        &.action-button--deleting {

            &:first-child {
                margin-right: 6px;
            }
        }
    }

    .payment-method-image {
        max-width: 24px;
        max-height: 100%;
    }

    .payment-method-img {
        display: inline-block;
        vertical-align: middle;

        img {
            height: auto;
            max-height: 100%;
            max-width: 24px;
            vertical-align: middle;
            width: auto;
        }

        &.fixed-size {
            img {
                @include mix.square(20px, true);
                object-fit: scale-down;
            }
        }
    }

    .payment-icon {
        display: inline-block;

        @media (max-width: var.$phone) {
            width: 48px;
        }
    }

    .payment-method-name {
        margin-right: 5px;
        white-space: nowrap;
    }

    .payment-method-info {
        padding-right: var.$regularPad;
    }

    .name-container {
        max-width: 172px;
        width: 172px;
    }

    .method-container {
        @include mix.flex-gap(8px, center);
    }

    .method-info-container {
        @include mix.flex(center, space-between);
        max-width: calc(100% - 33px);
        width: 100%;

        > p {
            margin: 0;
            @include mix.flex-gap(5px, center);
            white-space: nowrap;
            max-width: calc(100% - 62px);
        }
    }
    
    .text-xs {
        font-size: 11px;
    }

    .animated-text {
        transition: transform 0.5s ease-in-out;
    }

    .bank-name {
        max-width: 168px;
    }
}
