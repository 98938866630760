@use 'styles/variables' as var;
@use 'styles/mixins' as mix;

#cx-places-autocomplete-input {
    .save-button {
        margin-left: auto;
    }

    .mdc-select--outlined {
        .mdc-select__anchor {
            height: 50px !important;
        }
    }

    #cx-text-input {
        &.secondary-input {
            &:hover {
                outline: none;
            }

            > .cx-search-input {
                border-radius: 12px !important;
            }
        }
    }

    .circle-icon {
        top: 16px;
        right: 16px;
        height: 17px;
        width: 16px;
    }

    .circle-autocomplete-icon {
        top: 24px;
        right: 16px;
        height: 17px;
        width: 16px;
    }

    .mdc-list-item__text, .mdc-select__selected-text {
        font-size: var.$medium;
    }

    .select-input {
        padding-right: 41px;
    }

    .map-icon {
        position: absolute;
        top: 13px;
        right: 25px;
        color: var.$purple-text;
        width: 16px;
        height: auto;
    }

    .location-input {
        background-color: var.$backgroundcontrol !important;
    }

    .place-suggestions-container .pac-container {
        position: relative !important;
        background-color: transparent;
        border: none;
        padding: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 15px;
        box-shadow: none !important;
        width: 100% !important;

        .pac-item, .pac-item-query, .pac-matched {
            font-weight: 400 !important;
            font-size: var.$small !important;
            font-family: var.$primaryfont !important;
        }

        .pac-item {
            text-align: left;
            border: none;
            color: var.$faded-black !important;
            padding: 0 !important;

            &:hover {
                cursor: pointer;
                background: transparent;
                color: var.$faded-black !important;

                .pac-item-query {
                    color: var.$faded-black !important;
                }

                .pac-matched {
                    color: var.$black !important;
                }
            }

            &.store-address {
                @include mix.flex-gap(5px, center);
                color: var.$cx-purple !important;

                .pac-item-query {
                    color: var.$cx-purple !important;
                }

                .material-icons-outlined {
                    font-size: var.$regular !important;
                }

                &:hover {
                    color: var.$cx-purple !important;
                }
            }
        }

        .pac-matched {
            color: var.$black !important;
        }
    }

    .input-label {
        font: 400 #{var.$small}/14.06px var.$primaryfont;
        letter-spacing: 0.095px;
        text-align: left;
        color: var.$mediumblack;
        opacity: .5;
        margin-bottom: var.$smallPad !important;
        width: fit-content;
    }
}

.pac-container {
    margin-top: 8px;
    background-color: var.$inputgray;
    border-color: var.$inputgray;
    border-radius: 12px;
    padding: 12px;
    font-family: var.$primaryfont;
    top: unset !important;
    left: unset !important;

    .pac-item {
        border-color: var.$inputgray;
        color: var.$input-placeholder;
        font-size: var.$regular;

        &:hover {
            color: var.$purple-text;

            .pac-item-query {
                color: var.$purple-text;
            }
        }

        .pac-icon-marker {
            display: none;
        }
    }

    .pac-item-query {
        color: var.$input-placeholder;
        font-size: var.$regular;
    }

    @media (max-width: var.$desktop) {
        .pac-item {
            font-size: var.$small;
        }

        .pac-item-query {
            font-size: var.$small;
        }
    }
}

.pac-logo:after {
    display: none;
    background-image: none;
    height: 0;
    padding: 0;
}
