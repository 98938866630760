@import 'styles/variables';

#payment-information {
    .info-scrollbar {
        background-color: $backgroundcontrol;
        padding: 20px;
        max-height: 200px;
        line-height: 32px;
    }

    .caption-icon {
        margin-right: 10px;
    }
}
