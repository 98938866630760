@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-search-drawer {
    #drawer-content {
        overflow: hidden;
        padding-block: 1.25rem;
        @include m.flex(stretch, flex-start, column);
        gap: 1.25rem;

        > div {
            height: 2.5rem;
            gap: v.$regularPad;
            padding-inline: 1.25rem;
        }
    }

    button {
        @include m.flex(center, center);

        .mdc-button__icon {
            font-size: 17px;
            @include m.square-flex-center(v.$extralarge);
            color: rgba(255, 255, 255, 0.5);
            margin: 0;
        }
    }

    .drawer {
        width: 100%;
        z-index: 1050;
    }

    .search-button {
        height: 50px;
        width: 100%;

        &:active {
            background: linear-gradient(90.12deg, #2C006F 2.45%, #002E80 101.9%);
        }
    }

    .close-button {
        width: 100%;
        height: 100%;
        color: v.$purple-text;
        font-size: v.$small;
        font-weight: normal;
        line-height: 13px;
        letter-spacing: 0.352px;
        background-color: transparent;
        border: none;
        @include m.flex(center);

        .mdc-button__icon {
            color: rgba(201, 201, 204, 1);
        }
    }

    .material-icons {
        font-size: 17px;
    }

    .custom-container {
        background-color: v.$slateblue-off-low-opacity;
        border-radius: 4px;
        width: 100%;
        height: 2.5rem;
        flex: 1;
    }

    .mdc-text-field {
        width: 100%;
        height: 100%;

        .mdc-text-field__input {
            font-size: 0.75rem;
            line-height: 13px;
            font-weight: 300;
            letter-spacing: 0.352px;
        }

        .mdc-text-field__icon {
            color: v.$purple-text !important;
            font-size: 1rem;
        }

        .mdc-text-field__icon--leading {
            margin-right: 20px;
        }

        .mdc-text-field__icon--trailing {
            pointer-events: auto;
            cursor: pointer;
        }
    }

    .input-password {
        font-size: 0.75rem;
    }

    .mdc-drawer--animate,
    .mdc-drawer--closing {
        transform: translateY(-100%);
    }

    .mdc-drawer--opening {
        transform: translateY(0);
    }

    .mdc-text-field__icon {
        margin-left: 12px;
    }

    .mdc-list-item {
        height: 40px;
        margin: 0;
        pointer-events: auto;
    }

    .mdc-list-item__content {
        pointer-events: auto;
    }

    .input-background:not(.mdc-text-field--disabled) .mdc-text-field__input {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 70%;
    }

    .mdc-ripple-surface:after {
        background-color: v.$transparent;
    }

    .mdc-button.mdc-ripple-upgraded .mdc-button__ripple:before {
        transform: scale(var(--mdc-ripple-fg-scale,1));
        opacity: 0;
    }

    .chick-icon {
        width: 171px;
        height: 171px;
    }

    section[data-simplebar] {
        max-height: none;
        height: 100%;
        padding-right: 1.25rem;
        $spacing: 20px;

        & .simplebar-content {
            @include m.flex(stretch, flex-start, column);
            gap: v.$regularPad;
            height: 100%;
        }
    }

    .no-search-results {
        margin-top: 0.625rem;
        @include m.flex(center, center, column);
        gap: 1.25rem;

        span {
            font-size: v.$medium !important;
        }
    }

    &.is-support {
        @include m.media(v.$phone) {
            .search-button {
                margin-left: -7px;
            }
        }
    }

    .text-medium-info {
        font-size: v.$medium;
        letter-spacing: 0.016px;
        font-weight: 400;
        line-height: 16px;

        @include m.media(v.$tablet) {
            font-size: v.$regular !important;
            line-height: 14px;
        }
    }
}
