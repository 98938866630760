@import "intl-tel-input/build/css/intlTelInput.css";
@import 'styles/variables';

#cx-phone-input {
    .iti--allow-dropdown .iti__flag-container,
    .iti--separate-dial-code .iti__flag-container {
        right: auto;
        left: auto;
    }

    .iti.iti--allow-dropdown.iti--separate-dial-code {
        width: 100%;
    }

    .iti--separate-dial-code .iti__selected-flag {
        background-color: $backgroundcontrol;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;

        &:hover {
            background-color: $lilac-hover;
        }
    }

    .iti__selected-flag {
        background-color: unset;
    }

    .iti--inline-dropdown {
        width: 100%;
    }

    .iti__country-list {
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
        border: none;
        max-height: 185px;
        width: 300px;

        .iti__country {
            &.iti__highlight {
                background-color: $lilac-hover-medium;
                border-radius: 4px;
            }

            & .iti__country-name {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
                max-width: 195px;
            }
        }

        .iti__divider {
            border-bottom: 1px solid $primaryborder;
            opacity: 0.1;
        }

        &::-webkit-scrollbar {
            width: 12px;
        }
           
        &::-webkit-scrollbar-track {
            background-color: $white;
            border-radius: 4px;
        }
           
        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            background-color: $purple-text;
            border: 4px solid $white;
            border-radius: $largeplus;
        }
    }

    .iti input,
    & .iti input[type="text"],
    & .iti input[type="tel"] {
        padding-right: 36px;
    }

    .iti input {
        border: 0;
        border-radius: 12px;
        background-color: $backgroundcontrol !important;
        box-shadow: none;
        transition: none;
        height: 50px !important;
    }

    .iti {

        &__arrow {
            border-bottom-color: transparent;
            border-top-color: transparent;
        }

        &:hover {
            input {
                outline: 1px solid $purplehover;

                &.cx-input--error {
                    outline: 1px solid $red-error-hover;
                }

                 &.cx-input--success {
                    outline: 1px solid $success-green-hover;
                }
            }
        }

        &:has(.iti__arrow.iti__arrow--up) {
           input {
                outline: 1px solid $purple-text;
                
                &.cx-input--error {
                    outline: 1px solid $red-error;
                }

                &.cx-input--success {
                    outline: 1px solid $success-green;
                }
            }
        }

        & input:focus {
            outline: 1px solid $purple-text;

            &.cx-input--error {
                outline: 1px solid $red-error;
            }

            &.cx-input--success {
                outline: 1px solid $success-green;
            }
        }
    }

    #code {
        > input {
            padding-right: 22px;
        }

        &.cx-form-control--warning {
            outline: 1px solid rgba(254, 215, 102);
        }
    }

    .confirm-button {
        position: absolute;
        right: 20px;
        top: 7px;
    }

    .button-blue-border {
        border: 1px solid $blue;
        height: 50px !important;
    }

    .mini-spinner-icon {
        position: absolute;
        top: 12px;
        right: 1.3rem;
    }

    .circle-icon {
        height: 17px;
        position: absolute;
        right: 1.6rem;
        top: 1rem;
        width: 16px;
    }

    .white-x-mark {
        position: absolute;
        right: 1.5rem;
        top: 1rem;
    }

    .btn {
        background: linear-gradient(90.12deg, #4300a9 2.45%, #00338c 101.9%);
        border-radius: 6px;
    }

    .resend-code {
        font-size: $small;
    }

    .font-weight-bold {
        font-weight: bold;
    }

    .iti__arrow--up .global-arrow-icon {
        transform: rotate(180deg);
    }

    .global-arrow-icon {
        height: 9px;
        position: absolute;
        right: 4px;
        top: 20px;
        width: 8px;
        filter: invert(32%) sepia(0%) saturate(21%) hue-rotate(285deg) brightness(96%) contrast(85%);
        transition: .3s ease;
    }

    .iti__country-list {
        overflow-y: auto;
    }

    .iti--inline-dropdown {
        .iti__dropdown-content {
            border: unset !important;
            background-color: $white;
            border-radius: 12px;
            z-index: 100;

            input {
                background: $white !important;
                border-radius: 12px 12px 0 0;
                outline: unset !important;
            }
        }
    
        .iti__country-list {
            border-top: unset;
            border-radius: 0 0 12px 12px;
            box-shadow: unset;
            width: 100%;
        }
    }

    .copy-text-bellow {
        color: $black !important;
    }
}
