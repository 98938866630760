@import 'styles/variables';
@import 'styles/mixins';

#cx-lookup {
    height: 52px;
    .close-icon {
        right: 11.5px;
        color: $purple-text;
        height: 100%;
        padding-top: 16px;
    }

    .mdc-text-field--disabled {
        background: $slateblue-off-low-opacity;
    }

    .mdc-text-field--filled:before {
        display: unset;
        @include square(unset);
        content: unset;
        vertical-align: unset;
    }

    .mdc-text-field {
        align-items: center;

        input {
            margin-bottom: 0;
            font-size: $regular;
            color: $darkergray;
        }
        
        &.mdc-text-field--focused {
            background-color: $state-layer-color !important;
            .mdc-select__dropdown-icon {
                transform: rotate(180deg);
            }
        }
    } 

    .input-margin-label .mdc-text-field__input {
        margin-bottom: -12px;
    }

    .mdc-text-field__input {
        @include truncate-text(1);
        width: 94%;
    }

    .disabled-floating-label.mdc-text-field--label-floating .mdc-floating-label,
    .mdc-floating-label {
        top: 50% !important;
        transform: translateY(-50%) !important;
        font-size: $regular;
    }

    @include line-separators-items(mdc-list-item);

    .always-show-label .mdc-floating-label {
        display: unset !important;
    }

    .always-show-label .mdc-floating-label--float-above,
    .mdc-text-field--label-floating .mdc-floating-label {
        transform: translateY(-104%) scale(.857) !important;
    }

    .mdc-text-field--focused:not(.always-show-label) .mdc-floating-label {
        display: none;
        font-family: inherit;
        font-size: $regular !important;
    }

    .mdc-select__dropdown-icon {
        margin-left: auto;
        margin-right: 1px;
        color: $purple-text;
        transform: rotate(0deg);
        transition: .15s ease-in-out;
    }

    .align-vertically-main-container {
        @include flex-gap(6px, center);
        width: 100%;
    }

    .align-vertically-first-container {
        flex: 0 0 30px;
        background-color: $purple-text;
        color: $white !important;
        border-radius: 100%;
        font-size: $small;
        @include square(30px);
    }

    .mdc-list-item__primary-text span {
        color: $gray-blue;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: $select-gray;
    }

    .currency-icon {
        @include square(24px);
        border-radius: 100%;
        object-fit: cover;
    }

    .mdc-text-field__affix--prefix {
        padding-top: 8px;
    }
}
