@use "styles/variables" as v;
@use "styles/mixins" as m;

#cx-no-results {
    @include m.flex($align: center);
    gap: 20px;

    img {
        @include m.square(42px);
        padding: 3px;
    }

    span {
        font: 300 16px/20px v.$primaryfont;
        color: v.$color-grey-900;
    }
}
