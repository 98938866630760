@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-order-row {
    --column-width: 116px;
    --tablet-column-width: 96px;
    --mobile-column-width: 75px;
    --mobile-id-width: 40px;
    --left-padding: 24px;
    --status-width: 110px;
    --tablet-status-width: 75px;
    --mobile-row-height: 117px;
    --md-icon-size: 24px;

    .hover-effects {
        &:hover {
            #cx-list-item {
                background: v.$secondarycolor;
            }
        }

        &:active {
            #cx-list-item {
                background: v.$transparent-blue;
            }
        }
    }

    .order-row {
        padding-block: v.$smallPad;
        padding-left: 15px;
        padding-right: var(--left-padding);
        height: 60px;
        @include m.flex(center);

        @media (width <= v.$phone) {
            height: var(--mobile-row-height);
            align-items: normal;
            padding: 15px;
        }
    }

    .row-container {
        flex: 1;
        @include m.flex(center, space-between);

        @media (width <= v.$desktop) {
            gap: 24px;
        }

        @media (width <= v.$phone) {
            align-items: normal;
        }
    }

    p {
        margin-bottom: 0 !important;
        font-family: v.$primaryfont;
        font-size: v.$regular;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.001em;
        text-align: left;
        color: v.$gray-default;

        @media (width <= v.$desktop) {
            font-size: v.$small;
            line-height: 14px;
        }

        @media (width <= v.$phone) {
            font-size: v.$tiny;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 12px;
            letter-spacing: 0.38px;
            margin-bottom: 5px !important;
            color: v.$gray-default;

            &.data {
                font-size: v.$small;
                font-weight: 300;
                text-transform: none;
                line-height: 14px;
                margin-bottom: 0 !important;
                color: v.$gray-default;
            }
        }
    }

    .id-container {
        @include m.flex-gap(12px, center);
        @include m.container-fluid(156px);

        @media (width <= v.$desktop) {
            gap: 8px;
            max-width: var(--tablet-column-width);
        }
    }

    .valid-width {
        @include m.container-fluid(196px);

        @media (width <= v.$desktop) {
            max-width: var(--tablet-column-width);
        }
    }

    .subscription-container {
        @include m.flex-gap(12px, center);
        @include m.container-fluid(310px);

        @media (width <= v.$desktop) {
            gap: 8px;
            max-width: var(--tablet-column-width);
        }
    }

    .option-width {
        @include m.container-fluid(var(--column-width));

        @media (width <= v.$desktop) {
            max-width: var(--tablet-column-width);
        }
    }

    .status-width {
        @include m.container-fluid(var(--status-width));

        @media (width <= v.$desktop) {
            max-width: var(--tablet-status-width);
        }
    }

    .payment-method-icon {
        @include m.square(40px);
        border-radius: 50%;
        @include m.flex(center, center);
        background-color: v.$white;
    }

    .mobile-top-row {
        @include m.flex-gap(v.$smallPad, center);
    }

    .mobile-top-data {
        @include m.flex-gap(25px, center, flex-end);
        flex: 1;
    }

    .mobile-middle-row {
        @include m.flex-gap(25px, center, flex-end);

        .mobile-order-data {
            @include m.container-fluid($width: unset);

            .data {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
                word-break: break-all;
            }
        }
    }

    .mobile-bottom-row {
        @include m.flex-gap(25px, center, flex-end);
        margin-left: 50px;

        @media (width <= v.$smallphone) {
            gap: v.$smallPad;
        }
    }

    .mobile-order-data {
        @include m.container-fluid(var(--mobile-column-width));

        &.column-padding {
            margin-right: 15px;
        }
    }

    .arrow-style {
        @include m.square(12px);
        @include m.flex(center, center);

        > img {
            height: 12px;
        }
    }
}
