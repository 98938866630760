@import 'styles/variables';

#cx-pdf-viewer {
    .close-icon {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .mdc-dialog__surface {
        min-width: 1280px;
        height: 700px;
        border-radius: 12px;

        & .mdc-dialog__header+.mdc-dialog__content {
            padding: 55px 42px 38px 48px;
        }
    }

    .pdf-container {
        width: 100%;
    }

    @media (max-width: $tablet) {
        .mdc-dialog__surface {
            min-width: 720px;
        }
    }

    @media (max-width: $phone) {
        .close-icon {
            right: 10px;
            top: 10px;
        }
    
        .mdc-dialog__surface {
            min-width: 354px;
            height: 532px;
    
            & .mdc-dialog__header+.mdc-dialog__content {
                padding: 33px 31px 33px 28px;
            }
        }
    }
}
