@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-pagination {
    .mdc-notched-outline__leading {
        border-width: 1px !important;
    }

    .mdc-notched-outline__trailing {
        border-width: 1px !important;
    }

    .mdc-text-field {
        background-color: v.$transparent;
    }

    .mdc-text-field--focused {
        background: v.$white;
        outline: 2px solid v.$purple-text;
        
        .mdc-text-field__input {
            color: v.$black;    
            
            &::placeholder {
                color: v.$transparent;
            }
        }
    }

    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading {
        border: none !important;
    }

    .mdc-text-field__input {
        font-size: v.$medium;
        letter-spacing: 0.68px;
        color: v.$darkergray;
        caret-color: v.$darkergray;

        &::placeholder {
            padding: 0 3px;
            caret-color: v.$darkergray;
        }

        @include m.media(v.$desktop) {
            font-size: v.$regular;
        }
    }

    .pagination {
        justify-content: center;
        gap: v.$smallPadPlus;

        @include m.media(v.$phone) {
            gap: v.$smallPad;
        }
    }

    .pagination a {
        background-color: v.$transparent;
        color: v.$darkergray;
        @include m.size(v.$bigPad, v.$bigPad);

        @include m.media(v.$desktop) {
            @include m.size(v.$mediumPad, v.$mediumPad);
        }
    }

    .navigation-arrow {
        border: 1px solid v.$purple-text;
        border-radius: v.$thinborder;

        &:active {
            border: 2px solid v.$purple-text;
        }
    }

    .page-number:not(.page-number.highlighted),
    .mdc-text-field:not(.mdc-text-field--focused) {
        &:hover {
            border-radius: v.$thinborder;
            outline: 1px solid v.$purple-text;
        }

        &:active {
            border-radius: v.$thinborder;
            outline: 2px solid v.$purple-text;
        }
    }

    .page-number {
        border: none;
    }

    .page-link{
        transition: none;
        font-size: v.$medium;

        &:focus {
            box-shadow: none;
        }

        @include m.media(v.$desktop) {
            font-size: v.$regular;
        }
    }

    .highlighted {
        background: v.$purple-text !important;
        border-radius: v.$thinborder;
        color: v.$white !important;

        &.page-dots {
            width: 20px !important;
            height: 20px !important;
        }
    }

    .input-pagination {
        @include m.size(v.$bigPad, v.$bigPad);
        padding: 0 v.$small;
        border-radius: v.$thinborder;
        text-align: center;

        @include m.media(v.$desktop) {
            @include m.size(v.$mediumPad, v.$mediumPad);
            padding: 0 6px;
        }
    }

    .highlighted.page-dots,
    .pagination a.page-dots {
        background: v.$darkergray-medium;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        margin-left: 33px;
    }

    .pagination a.page-dots:not(.highlighted) {
        transform: translateY(3px);
    }
}
