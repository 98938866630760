@import 'styles/variables';
@import 'styles/mixins';

#page-content-image {
    img {
        height: auto;
        max-height: 130px;
        object-fit: cover;
        border-radius: 10px;
    }
}
