@import "styles/variables";

#cx-paragraph-image {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 530px;
    align-items: center;

    @media (width <= $desktop) {
        height: 406px;
        grid-template-columns: repeat(1, 100%);
    }

    @media (width <= $tablet) {
        padding-top: unset;
        height: 580px;
    }

    @media (width <= $phone) {
        align-items: normal;
    }

    .first {
        order: 1;
    }

    .second {
        order: 2;
    }

    img.first {
        justify-self: start;
    }

    img.second {
        justify-self: end;
    }

    .icon {
        @include square(75px);

        @media (width <= $desktop) {
            @include square($extrahugeplustitle);
        }

        @media (width <= $phone) {
            @include square($extrahugetitle);
        }
    }

    .container-text-icon {
        display: flex;
        flex-flow: row nowrap;
        gap: $largeplus;

        @media (width <= $widescreen) {
            flex-flow: column nowrap;
        }

        @media (width <= $desktop) {
            flex-flow: column nowrap;
        }

        @media (width <= $phone) {
            min-width: 267px;
        }
    }

    .caption-title {
        font-size: $huge;
        margin-bottom: $extralarge;
        
        h2 {
            margin-bottom: 0;
        }

        @media (width <= $widescreen) {
            margin-bottom: 0;
            margin-left: $tiny;
        }

        @media (width <= $desktop) {
            font-size: $huge;
            width: 255px;
            margin-bottom: 0;
            margin-left: $tiny + 1px;
        }

        @media (width <= $phone) {
            font-size: $medium;
            font-weight: 400;
        }
    }

    .caption-text {        
        @media (width <= $desktop) {
            font-weight: 400;
            font-size: $medium;
            line-height: $huge;
        }

        @media (width <= $phone) {
            font-size: $small;
            font-weight: 400;
        }
    }

    @media (width <= $tablet) {
        img.first,
        img.second {
            justify-self: center;
        }
    }

    @media (width <= $phone) {
        img.first,
        img.second {
            justify-self: center;
            align-self: center;
            margin-top: $moreplushuge;
        }
    }

    .methods-row {
        display: grid;
        grid-template-columns: repeat(4, $extrahugetitle);
        column-gap: 9px;

        @media (width <= $phone) {
            padding-bottom: 3.5rem;
            margin-bottom: 4rem;
        }
    }

    .method-icon img {
        max-height: 44px;
        max-width: 44px;
    }

    .banner-img {
        width: 448px;
        height: auto;

        @media (width <= $extrascreen) {
            width: 571px;
        }

        @media (width <= $desktop) {
            width: 40vw;
            height: 270px;
        }

        @media (width <= $tablet) {
            display: none;
        }

        @media (width <= $phone) {
            margin-top: 0;
            width: 245px;
            height: auto;
        }
    }

    p {
        margin-right: 64px;

        @media (width <= $extrascreen) {
            margin-right: $giant;
        }

        @media (width <= $desktop) {
            margin-right: unset;
            margin-bottom: 0;
        }
    }
}
