@use 'styles/variables' as var;
@use 'styles/mixins' as m;

#cx-notification-bell {
    position: relative;
    @include m.flex-center();
    width: 172px;
    height: 100%;
    padding-bottom: 7px;
    cursor: pointer;
    overflow: visible;

    @media (width <= var.$desktop) {
        width: 49px;
    }

    #notifications {
        @include m.flex-center();
        gap: 6.5px;

        >span:not(.arrow) {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.28px;
            text-align: center;
        }

        .bell {
            margin-right: 2px;
            @media (width <= var.$desktop) {
                margin-right: 0;
            }
        }

        .arrow {
            --md-icon-size: 12px;
            padding: 4px 2px;
            transition: transform ease 0.2s;

            &.arrow-hovered {
                transition: transform ease 0.2s;
                transform: rotate(180deg);
            }
        }

    }

    &.open-dropdown{
        .notifications-container {
            display: block;
        }
    }

    .bell-spacer {
        transform: translateY(3px);

        @media (max-width: var.$tablet) {
            transform: translateY(2.5px);
        }
    }

    @media (width > var.$desktop) {
        &.bell-border-bottom {
            &::after {
                content: '';
                position: absolute;
                left: 11%;
                top: 96%;
                width: 79%;
                height: 2px;
                background-color: var.$cx-purple;
                pointer-events: none;
            }
    
            &.inverted {
                &::after {
                    background-color: var(--cg-grey-100);
                }
            }
        }
    }

    .notification-divisor {
        width: 100% !important;
        margin-block: 0 !important;
        transform: unset !important;
    }

    .notifications-container {
        display: none;
        width: 375px;
        padding-top: var.$regularPad;
        position: fixed;
        top: 64px;
        right: 175px;
        background-color: var.$white;
        border-radius: 4px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.40);
        z-index: 9999;
        
        @media (width <= var.$desktop) {
            @include m.square(100%);
            right: auto;
            left: 0;
            top: 0;
            z-index: 999;
        }
    }

    .container-control {
        padding: 0;
        padding-block: 18px var.$regularPad;
        margin-inline: var.$regularPad;

        .controls {
            width: 100%;
            height: 50px;

            @media (width <= var.$desktop) {
                width: unset;
            }
        }

        @include m.media(var.$tablet) {
            padding-block: 11px 0;
        }
    }

    .control-buttons {
        gap: 21px;

        @include m.media(var.$phone) {
            gap: 16px;
        }
    }

    .mark-read {
        font-size: var.$regular;
        white-space: nowrap;
        width: 117px !important;
        height: 50px;

        @media (max-width: var.$phone) {
            width: 113px !important;
        }
    }

    .mark-as-read {
        @include m.size(150px, var.$extrabigpad, true);
    }

    .mark-read-hover {
        opacity: 0.3;
    }

    #cx-notification .notification-body {
        margin-right: 16px;
    }

    .notification-badge {
        @include m.square(16px);
        padding: 2px;
        font-size: var.$tiny;
        letter-spacing: 0.3px;
        font-weight: 400;
        line-height: 12px;
        color: var.$color-grey-200;
        border-radius: 16px;
        left: 17px;
        top: -8px;
    }

    section[data-simplebar] {
        max-height: 560px !important;
        margin-bottom: var.$regularPad;
        overflow: unset;
        overflow-y: unset;

        @include m.fade(30px, transparent, var.$softwhite, 50px);

        @media (width <= var.$desktop) {
            height: 100% !important;
            top: var.$regularPad;
            max-height: calc(100% - 164px) !important;
        }

        @include m.media (var.$tablet) {
            @include m.fade(30px, transparent, var.$softwhite, var.$regularPad);
            top: 4px;
        }
    }

    .tab-mob-container {
        height: 40px;
        margin-bottom: var.$bigPad;
        margin-inline: var.$regularPad;
    }

    .material-close-icon {
        color: var.$purple-text;

        @include m.media(var.$tablet) {
            margin-right: 11px !important;
        }
    }

    .close-span {
        font-size: var.$small;
        font-weight: 400;
    }

    .first-button {
        margin-right: var.$smallPad;
    }

    cx-button {
        span {
            font-size: var.$medium;
            font-weight: 500;
        }
    }

    .mail-icon,
    .delete-icon {
        display: flex;
        background: none;
        border: none;
        outline: none;
        @include m.square(fit-content);
        opacity: 0.75;
    }

    .search-bar-container {
        height: 40px;
        margin-bottom: var.$regularPad;
        margin-inline: var.$regularPad;

        & .mdc-text-field__icon {
            font-size: var.$largeplusplus !important;
        }

        & .mdc-text-field__icon--leading {
            color: var.$gray-blue !important;
        }

        @media (width <= var.$desktop) {
            margin-bottom: 0;
            margin-inline: var.$regularPad;
            flex-grow: 1;

            & .mdc-text-field__icon--leading {
                font-size: var.$largeplusplus;
            }

            .mdc-text-field__icon--trailing {
                font-size: var.$largeplusplus;
                padding-left: 0;
                padding-right: 5px;
            }

            & input {
                font-size: var.$regular;
            }
        }

        @include m.media(var.$tablet) {
            margin-inline: var.$smallPad 0;
            margin-block: 0;
        }
    }

    & div:has(> .search-bar) {
        flex: 1;
        height: 50px;

        @media (width <= var.$desktop) {
            height: 40px !important;
        }
    }

    .search-bar {
        border-radius: 4px !important;
        height: 50px !important;
        padding-right: 0;

        @media (width <= var.$widescreen) {
            border: 0.5px;
            border-radius: 4px;
            flex: 1;
            width: 100% !important;
            margin-left: 0;
        }

        @media (width <= var.$desktop) {
            height: 40px !important;
        }

        .mdc-floating-label--float-above {
            @media (var.$widescreen <= width) {
                transform: translateY(-90%) scale(0.75);
            }
        }

        .mdc-text-field__icon {
            font-size: var.$medium;
        }

        .mdc-floating-label {
            font-size: var.$regular;
        }

        .mdc-line-ripple {
            display: none;
        }

        .mdc-text-field__input {
            font-size: var.$regular;
            font-weight: normal;
            line-height: 1rem;
            height: auto;
            translate: 0 -10px;

            @media (var.$widescreen <= width) {
                translate: 0 -40%;
            }
        }

        .mdc-text-field__icon--leading {
            margin-right: var.$smallPad;
        }

        .mdc-text-field__icon--trailing {
            pointer-events: auto;
            cursor: pointer;
        }
    }

    .material-symbols-outlined {
        font-variation-settings:
            'FILL' 0,
            'wght' 300,
            'GRAD' -25,
            'opsz' 24
    }

    .earlier-notifications-container {
        margin-top: var.$regularPad;
        font-size: var.$large;

        @media (max-width: var.$phone) {
            margin-top: var.$smallPad;
            font-size: var.$medium;
        }
    }

    .earlier-divisor,
    .new-divisor {
        margin-left: var.$regularPad;
        margin-bottom: var.$smallPad;
        font-size: var.$medium;
        line-height: var.$medium;

        @media (max-width: var.$desktop) {
            margin-top: var.$regularPad;
            margin-bottom: unset;
            font-size: var.$medium;
        }

        @include m.media(var.$tablet) {
            margin-top: 11px;;
        }

        @media (width <= var.$phone) {
            margin-top: 0;
            line-height: 25px;
            padding-bottom: var.$smallPadPlus;
        }
    }

    .select-box-container {
        min-width: 147px;
        min-height: 105px;
    }

    .icon-container {
        margin-right: var.$smallPadPlus;
        margin-top: 18px;
    }

    #cx-checkbox-filter {
        .bullet:not(.select-box-container .bullet) {
            margin-right: 0;
        }

        .bullet {
            @include m.square(24px);
        }

        .checkbox-container {
            padding: 5px;
            translate: 0 2px;

            @include m.media(var.$tablet) {
                translate: -95px 2px;
            }
        }

        .bullet.checkbox-external .material-icons {
            color: var.$green !important;
        }

        .button-icon {
            padding-inline: 6px 0;
        }

        .select-arrow {
            color: var.$purple-text !important;
        }
    }

    #cx-text-input {
      
        .cx-search-input {
          background-color: var.$gray-input !important;
          border-radius: 4px;
          box-shadow: none;
      
          .mdc-text-field:not(.mdc-text-field--disabled)
            .mdc-text-field__icon--leading,
          .mdc-text-field__input::placeholder {
            color: var.$black;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.3;
          }
        }
      
        .mdc-text-field:not(.mdc-text-field--disabled)
          .mdc-text-field__icon--leading {
          color: var.$black;
          @include m.square(16px);
          opacity: 0.3;
          margin-right: 15px;
          margin-bottom: 5px;
        }
    }

    .notification-content {
        margin-top: 6px;
    }

    .title-notification-section {
        height: 20px;
        padding-inline: var.$regularPad;

        @include m.media(var.$tablet) {
            height: 30px;
            align-items: center;
            margin-bottom: 6px;
        }

        @include m.media(var.$phone) {
            height: unset !important;
            margin-bottom: 8px;
        }

        .notification-title {
            font-size: var.$large !important;
            line-height: 25px;
            height: var.$regularPad;
        }
    }

    .mdc-floating-label {
        color: var.$semi-black;
    }

    #cx-notification {
        .notification-header,
        .notification-description {
            max-width: unset;

            &.title {
                font-size: var.$medium;
                line-height: var.$largeplus;
                font-weight: 500 !important;
                margin-bottom: var.$tinyPad;
            }

            &.title-is-read {
                font-size: var.$medium;
                font-weight: 300 !important;
            }

            &.description {
                margin-right: 25px;
                font-size: var.$regular;
                font-weight: 300 !important;
                line-height: 130%;
                @include m.truncate-text(3);

                @include m.media(var.$phone) {
                    line-height: 14px;
                    margin-bottom: 10px;
                    @include m.truncate-text(3);
                }
            }
        }

        .notification-date {
            color: var.$white;
            opacity: 0.25;
            margin-bottom: var.$smallPad;
            margin-right: var.$regularPad;
            line-height: 15px;

            @media (width <= var.$desktop) {
                right: var.$regularPad;
                margin-bottom: 14px;
            }

            @include m.media(var.$phone) {
            }
        }
    }

    .delete-buttons {
        @media (min-width: var.$phone) and (max-width: var.$desktop){
            min-width: 235px;
        }
    }

    .no-result-section {
        margin-top: var.$smallPad;
    }

    .no-results {
        margin: var.$regularPad 68px !important;

        @include m.media(var.$tablet) {
            width: 50%;
        }

        @include m.media(var.$phone) {
            width: 85%;
        }
    }

    .no-title {
        margin-top: 22px;
    }

    .last-element {
        margin-bottom: var.$regularPad;
    }
}

