@use 'styles/variables' as v;
@use "styles/mixins" as m;

cx-number-arrows {
    height: 100%;

    @include m.any-direct-parent() {
        position: relative;
    }

	color: v.$cx-purple;
}

#cx-number-arrows {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.15s linear, opacity 0.15s linear;

    .button-container {
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        gap: 5px;

        .quantity-input {
            max-height: 20px;
            min-width: 30px;
            padding: 0 !important;
        }
    
        &.suffix {
            right: 10px !important;
        }
    
        .number-arrow-container {
            @include m.square(9px);
            @include m.flex-center();
            transition: all .15s ease-in-out;
            margin-right: 3px;
        }
    
        .number-arrow-container:first-child {
            margin-bottom: -1px;
        }
    
        .number-arrow-container:last-child {
            margin-top: -1px;
        }
    
        @media (min-width: calc(v.$extralargescreen + 1px)) {
            right: 10px !important;
    
            .number-arrow-container {
                @include m.square(11.4px);
            }
        }
    }

    .extra-button-container {
        margin-right: 15px;

        .extra-button {
            border-radius: 4px;
            @include m.square(18px);
            background: v.$blue;
            border: 0;
            color: v.$white;
            padding: 0;
            line-height: 0;
            box-shadow: none;
        }
    
        .mdc-text-field {
            width: 36px;
            padding-inline: 0;

            &__input {
                text-align: center;
            }
        }
     
        .btn:disabled {
            opacity: 0.3;
        }
    }
}
