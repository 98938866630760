@import 'styles/variables';

#cx-address-input {
    .meetup-combo {
        flex-wrap: wrap;
        height: auto;
        margin-top: 10px;

        @media (width > $phone) {
            background-color: $backgroundcontrol;
        }

    }

    .time-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;

        @media (width > $phone) {
            width: 60%;
        }
    }

    .main-container,
    .time-container {
        @media (max-width: $phone) {
            border: none;
            border-radius: none;

            &:hover:not(.error-border) {
                border: $purplehover 1px solid !important;
            }

            &.error-border {
                border: $red-hover 1px solid !important;

                &:hover {
                    border: $red 1px solid !important;
                }
            }
        }
    }

    .joined-input-container {
        @media (width > $phone) {
            &:hover:not(.error-border) {
                border: $purplehover 1px solid !important;
            }
        }

        &.error-border {
            border: $red-hover 1px solid !important;

            &:hover {
                border: $red 1px solid !important;
            }
        }

        @media (max-width: $phone) {
            border: none;
            border-radius: none;

            &:hover:not(.error-border) {
                border: none;
            }

            &.error-border {
                border: none;

                &:hover {
                    border: none;
                }
            }
        }
    }

    .joined-input-container,
    .time-container,
    .main-container {
        border: $transparent 1px solid !important;
        border-radius: 12px;
    }

    ::placeholder {
        color: $input-placeholder;
    }

    .datepickerInput {
        padding-left: 10px;
        height: 50px;
        font-size: 16px;
    }

    .calendar-icon {
        position: absolute;
        top: 13px;
        right: 25px;
        color: $purple-text;
        width: 16px;
        height: auto;
    }

    .circle-icon,
    .mini-spinner-icon {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 20px;
        height: 20px;
        font-size: $largeplusplus;

        @media (max-width: $phone) {
            top: 77px;
        }
    }

    .main-container {
        width: 40%;

        @media (max-width: $phone) {
            width: 100%;
            margin-bottom: 10px;
        }
    }


    @media (max-width: $phone) {
        .meetup-combo {
            .first-input {
                border-radius: 12px !important;
            }

            .middle-input {
                border-radius: 12px 0px 0px 12px;
            }

            .last-input {
                border-radius: 0 12px 12px 0;
            }

            .solo-input {
                border-radius: 12px;
            }
        }
    }
}

.flatpickr-calendar {
    width: auto;
    background-color: $backgroundcontrol !important;
    padding: 30px;
    border: none;
    margin-top: 3px;
}

.flatpickr-weekdaycontainer {
    .flatpickr-weekday {
        width: 30px !important;
    }
}

.dayContainer {
    .selected {
        background-color: $purple-text;
        border: $purple-text;
    }
}

.flatpickr-prev-month,
.flatpickr-next-month {
    filter: brightness(0) saturate(100%) invert(20%) sepia(93%) saturate(2702%) hue-rotate(248deg) brightness(61%) contrast(124%);
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    color: $midgray !important;
    font-weight: 700 !important;
}

.flatpickr-months {
    margin-bottom: 20px;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    left: 30px;
    top: 26px
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
    right: 30px;
    top: 26px
}
