@import 'styles/variables';

#pseudo-table {
    &:hover {
        background-color: $lightergray !important;
        cursor: pointer;
    }

    .divisor {
        margin-top: 10px !important;
        margin-bottom: 20px !important;
        background-color: $divisor-gray;
    }
    
    .pseudo-cell {
        width: 100px;
    }
    
    .truncated-text {
        &:hover {
            color: $lighterlilac;
        }
    }
}
