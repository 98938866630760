@use 'styles/variables' as v;

#cx-row-buttons {
    --max-width-selector: 380px;
    --width-button-animated: 125px;
    --height-selector: 48px;
    --background-color: #{v.$secondarycolor};
    --background-color-hover: #{v.$secondarycolor-hover};
    --background-color-active: #{v.$purple-text};
    --text-color: #{v.$black};
    --text-color-active: #{v.$white};

    position: relative;
    width: var(--max-width-selector);
    height: var(--height-selector);;

    .button-group {
        margin: 0;
        display: flex;
        background-color: var(--background-color);
        border-radius: v.$thinborder;

        @media (width >= v.$phone) {
            width: var(--max-width-selector);
        }

        button {
            z-index: 1;
            border: none;
            border-radius: v.$thinborder;
            background-color: transparent;
            font-family: v.$primaryfont;
            font-size: v.$regular;
            line-height: 16px;
            color: var(--text-color);
            height: var(--height-selector);
            width: var(--width-button-animated);
            transition: color 0.7s;
            font-weight: 300;

            &.active {
                color: var(--text-color-active);
                font-weight: 400;
            }
        }

        &:hover:not(.active) {
            @media (width >= v.$desktop) {
                background-color: var(--background-color-hover);
            }
        }
    }

    .button-animated {
        position: absolute;
        background-color: var(--background-color-active);
        width: var(--width-button-animated);
        height: var(--height-selector);
        padding: 16px v.$regularPad;
        border: none;
        border-radius: v.$thinborder;
        font-family: v.$primaryfont;
        font-size: v.$regular;
        line-height: 16px;
        text-align: center;
    }
}
