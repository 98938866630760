@import "styles/variables";

#cx-nav-preorder {
    height: 100%;
    background-color: $color-grey-100;
    padding: 40px 32px 40px 32px;

    .title-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 20px;
        gap: 12px;
        height: 44px;

        > div {
            gap: 12px;
        }

        h2 {
            font-size: $largeplus;
            color: $darkergray;
            font-weight: 500;
            margin-bottom: 0;
            line-height: 1;
            letter-spacing: 0.3px;
        }

        .mdc-radio {
            position: absolute;
            opacity: 0;
        }

        .mdc-radio + label {
            width: 104px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 17px;
            background-color: $color-grey-200;
            color: $color-grey-800;
            border-radius: 4px;
            cursor: pointer;
            font-size: $small;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:hover {
                background-color: $secondarycolor;
                color: $purple-text;
            }

            &:active {
                background-color: $transparent-blue;
            }

        }

        .purple-active,
        .purple-active .mdc-ripple-upgraded,
        .purple-active label {
            color: $purple-text;
            background-color: $transparent-blue;
            outline: 2px solid $cx-purple;
            border-radius: 4px;
        }
    }

    .currencies-list {
        width: 100%;
        margin-top: 10px;
    }

    .payment-methods-list {
        margin-top: 10px;
    }

    .currency-button {
        .mdc-radio {
            position: absolute;
            opacity: 0;
        }

        .mdc-radio + label {
            min-width: 136px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 20px;
            background-color: $color-grey-250;
            color: $color-grey-800;
            border-radius: 4px;
            cursor: pointer;
            font-size: $small;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            position: relative;
            outline: 1px solid transparent;
            transition: outline 0.3s;

            &:hover {
                background-color: $secondarycolor;
                color: $purple-text;
            }

            &:active {
                background-color: $transparent-blue;
            }
        }

        @media (width <= 1669px) {
            .mdc-radio + label {
                width: 10vw;
            }
        }

        @media (width <= 1399px) {
            .mdc-radio + label {
                width: 13vw;
            }
        }

        &.method-button {
            .mdc-radio + label {
                width: 165px;

                @media (width <= 1669px) {
                    width: 14vw;
                }

                @media (width <= 1399px) {
                    width: 21vw;
                }
            }
        }

        &.currency-button:not(.method-button) {
            .mdc-radio + label {
                width: 135px;
            }
        }

        label img {
            @include square($huge);
            margin-right: $extra-tiny;
            object-fit: cover;
        }

        label span {
            margin-right: 10px;
        }
    }

    .currency-button,
    .method-button {
        .mdc-radio + label {
            width: 165px;
        }
    }

    .preorder-content {
        height: fit-content;
        display: flex;
        flex-flow: row nowrap;
        gap: 20px;

        .separator {
            border-left: 1px solid $cx-purple;
            height: 140px;
            opacity: 1;
        }

        .currencies,
        .payment-methods,
        #horizontal-list-container .horizontal-list  {
            width: 907px;

            @media (width > $quadhdscreen) {
                width: 47.5vw;
            }

            @media (width <= $extralargescreen) {
                width: 47vw;
            }

            @media (width <= $extrascreen) {
                width: 46vw;
            }

            @media (width <= $widescreen) {
                width: 44.5vw;
            }

            h4 {
                font-size: $regular;
                font-style: normal;
                font-weight: 400;
                line-height: 1rem;
                margin-bottom: 0;
                color: $darkergray;
                letter-spacing: 0.035px;
            }
        }
    }

    #cx-text-input {
        width: 100%;
        margin-bottom: 20px;

        .mdc-select, .mdc-text-field {
            .mdc-floating-label {
                &--float-above {
                    transform: translateY(-79%) scale(.8571) !important;
                }
            }
        }

        .cx-search-input {
          background-color: $color-grey-250 !important;
          border-radius: 4px;
          box-shadow: none;
      
          .mdc-text-field:not(.mdc-text-field--disabled)
            .mdc-text-field__icon--leading,
          .mdc-text-field__input::placeholder {
            color: $color-grey-600;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.3;
            letter-spacing: 0.035px;
          }
      
          &:hover, &:focus {
            outline: $purple-text 1px solid;
            background-color: var(--cg-grey-300) !important;
          }

          &.mdc-text-field--focused {
            outline: $purple-text 2px solid;
            background-color: var(--cg-grey-350) !important;
          }
        }
      
        .mdc-text-field:not(.mdc-text-field--disabled)
          .mdc-text-field__icon--leading {
          color: $color-grey-700;
          @include square(16px);
          margin-right: 15px;
          margin-bottom: 5px;
        }

        .mdc-text-field:not(.mdc-text-field--disabled)
          .mdc-text-field__icon--trailing {
            color: $color-grey-700;
        }
    }

    .purple-border,
    .purple-border .mdc-ripple-upgraded,
    .purple-border label {
        background-color: $transparent-blue;
        outline: 2px solid $cx-purple;
        border-radius: 4px;

        .mdc-radio + label {
            color: $cx-purple !important;
            background-color: $transparent-blue;
        }
    }

    #cx-no-results {
        padding: 0 20px;
        width: 100%;
        height: 48px;
        margin-top: 20px;

        img {
            @include square(32px);
        }

        span {
            width: 490px;
        }
    }
}
