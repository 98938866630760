@import 'styles/variables';
@import "styles/_colors.scss";

#cx-progress-bar {
    font-family: $primaryfont;
    display: flex;
    align-items: center;
    position: relative;

    .verif-skeleton {
        width: 115px;
    }

    .skeleton-lvl-1 {
        width: 150px;
        height: 119px;
        margin-block: 5px 30px;

        @media (width <= $desktop) {
            width: 200px;
            height: 64px;
        }

        @media (width <= $phone) {
            width: 274px;
            height: 89px;
        }
    }

    .skeleton-lvl-2 {
        width: 150px;
        height: 108px;
        margin-block: 5px 10px;

        @media (width <= $desktop) {
            width: 200px;
            height: 102px;
        }

        @media (width <= $phone) {
            width: 240px;
            height: 108px;
        }
    }

    .skeleton-lvl-3 {
        width: 150px;
        height: 20px;
        margin-block: 5px 30px;

        @media (width <= $desktop) {
            width: 200px;
            height: 24px;
        }
    }

    ul {
        list-style-type: none;
        margin-block-start: 0px;
        margin-block-end: 0px;
        padding-inline-start: 0px;
        counter-reset: step;

        &.verification-pills {
            width: 20px !important;

            & .step {
                width: 20px;
                height: 20px;

                &.step-verification_success {
                    font-size: $smallplus;
                }

                &.step-active:not(.skeleton-loading) {
                    font-size: $tiny;
                }

                &.step-disabled {
                    font-size: $tiny;
                }
            }
        }
    }

    .step {
        background-color: rgba(197, 204, 229, 1);
        width: 32px;
        height: 32px;
        border: none;
        color: $cx-purple;

        &.step-active:not(.skeleton-loading) {
            background-color: $cx-purple !important;
            color: $white;

            .step-number::before {
                content: "circle";
            }
        }

        &.step-success {
            @extend .cx-background;
            color: $white;

            .step-number::before {
                content: "check";
            }
        }

        &.step-disabled {
            background-color: rgba(197, 204, 229, 1);
            color: $white;

            .step-number::before {
                content: "circle";
            }
        }

        &.step-verification_success:not(.skeleton-loading) {
            background-color: $success-green !important;
            color: $white !important;

            .step-number::before {
                content: "check";
            }
        }

        .step-number::before {
            content: counter(step);
            counter-increment: step;
        }
    }

    .level-title {
        font-size: 16px;

        &.skeleton-loading {
            width: 60px;
        }
    }

    .cx-linear-progress {
        .mdc-linear-progress__buffer-bar {
            background-color: rgba(197, 204, 229, 1);
        }

        .mdc-linear-progress__bar-inner {
            border-color: $cx-purple;
        }
    }

    .cx-linear-progress_success {
        .mdc-linear-progress__buffer-bar {
            background-color: rgba(197, 204, 229, 1);
        }

        .mdc-linear-progress__bar-inner {
            border-color: $success-green;
        }
    }

    .verification-column {
        margin-top: 40px;
        max-width: 180px;
    }

    .verification-container {

        cx-status-tag {
            & span {
                &:first-child {
                    font-size: 11px;
                }

                &:nth-child(2) {
                    font-size: $tiny;
                }
            }
        }

        .verification-column {
            max-width: 200px;

            & span:not(#cx-verification-status-tag > div > span, :first-child) {
                line-height: 12px;
            }
        }

        .tag {
            height: 20px;
            width: max-content;
        }

        @media ($phone <= width <= $desktop) {
            gap: 60px;
        }

    }

    .horizontal-progress-bar {
        width: 525px !important;
        height: 2px;
    }

    .cx-progress-bar-vertical {
        max-width: 300px;

        .line-skeleton {
            width: 2px;
            left: 8px;
            margin-right: 25px !important;
            padding: 1px;
            border-radius: 10px;
            background-color: $secondarycolor !important;
            max-height: 400px;

            ul {
                width: 5px;
            }

            li {
                left: -8px;
            }
        }

        @media (max-width: $phone) {
            overflow: hidden;
        }

        cx-status-tag span {
            &:first-child {
                font-size: 11px;
            }

            &:nth-child(2) {
                font-size: $tiny;
            }
        }

        .step {
            width: 20px;
            height: 20px;

            &.step-disabled {
                font-size: $tiny;
            }

            &.step-verification_success {
                font-size: $smallplus;
            }

            &.step-active {
                font-size: $tiny;
            }
        }

        ul {
            padding-bottom: 70px;
            width: 20px;
        }

        .cx-linear-progress_success {
            width: 340px;
            transform: translate(10px, 10px) rotate(90deg);
            transform-origin: left;
        }

        .verified {
            margin-right: 0 !important;
        }

        .verified span:nth-child(2) {
            color: $gray-blue;
        }

        .verification-column {
            margin-top: 0px;

            & span:not(#cx-verification-status-tag > div > span, :first-child) {
                padding-bottom: 10px;

                &:first-child {
                    padding-top: 4px;
                }

                &:last-child {
                    padding-bottom: 4px;
                }
            }
        }

        .verification-column {
            max-width: 150px;

            @media (max-width: $phone) {
                max-width: 300px;
            }
        }

        .verification-column-2 {
            max-width: 240px !important;

            span {
                max-width: 150px;

                @media (max-width: $phone) {
                    max-width: 300px;
                }
            }
        }

        .tag {
            height: 20px;
            width: max-content;
        }
    }

    cx-page-content-area > div {
        & > p {
            margin-bottom: 9px;
        }

        & > p:last-child {
            margin-bottom: 0;
        }
    }
}
