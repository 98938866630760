@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-rate-of-change {
    &, .cx-rate-of-change {
        height:100%;
    }

    .cx-rate-of-change {
        @include m.box-default();
    }
}
